import { marked } from 'marked';
import $ from 'jquery';

class About {
    constructor() {
        this.namespaces = [
            document.querySelector('.page-about'), 
            document.querySelector('.page-team'),
            document.querySelector('.page-subscribe')
        ].filter(Boolean);

        this.siteWidth = 1100;
        this.teamMembers = [];
        this.detailsPanel = document.querySelector('.details'); 
        this.memberTitle = document.querySelector('.team-member-name');
        this.mainImage = document.querySelector('.mainImageContainer');
        
        this.initPage();
    }

    initPage() {
        if (!this.namespaces.length) return;
        this.setupWindowEvents();
        this.setupListeners();
        this.loadTeamData();
        this.setupSubscriptions();
    }

    setupSubscriptions() {
        const subscribeForm = document.querySelector('.subscribe-form');
        if (!subscribeForm) return;
        subscribeForm.addEventListener('submit', this.subscribeHandler.bind(this));
    }

    subscribeHandler(e) {
        e.preventDefault();

        const formContainer = document.querySelector('.subscribe-form')
        const email = document.querySelector('.subscribe-email').value;
        const firstName = document.querySelector('.subscribe-firstname').value;
        const lastName = document.querySelector('.subscribe-lastname').value;
        const thankyou = document.querySelector('.thankyou');

        formContainer.classList.add('sent');

        setTimeout(() => {
            thankyou.classList.add('sent');
        }, 500);

        const myForm = e.target;
        const formData = new FormData(myForm);
        
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => console.log("Form successfully submitted"))
            .catch((error) => alert(error));
    }

    loadTeamData() {
        const teamJSONContainer = document.querySelector('.team-json');
        if (!teamJSONContainer) return;
        const teamJson = this.htmlDecode(teamJSONContainer.innerHTML);
        if (!teamJson) return;
        this.teamMembers = teamJson.attributes.teamMember;

        this.populatTeamGallery();
    }

    populatTeamGallery() {
        const list = document.querySelector('.member-list');

        if (!list) return;

        this.teamMembers.forEach((member,i) => {
            if (!member.image.data) return;
            const li = document.createElement('li');
            li.addEventListener('click', this.memberClickHandler.bind(this));
            if (i === 0) li.classList.add('active');
 
            li.innerHTML = `
                <div class="member" data-name="${member.name}" style="background-image: url(${member.image.data.attributes.url})"></div>
            `;
            list.appendChild(li);
        });
        
    }

    htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return JSON.parse(doc.documentElement.textContent);
    }

    setupListeners() {
    
    }

    memberClickHandler(e) { 
        const selected = this.teamMembers.find(member => member.name === e.target.dataset.name);

        const memberElements = document.querySelectorAll('.member');

        memberElements.forEach(member => member.parentElement.classList.remove('active'));
        e.target.parentElement.classList.add('active');
        
        this.detailsPanel.innerHTML = selected.details;
        this.memberTitle.innerHTML = selected.name;
        this.mainImage.style.backgroundImage = `url(${selected.image.data.attributes.url})`;
        this.detailsPanel.innerHTML = marked.parse(selected.details);
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
        
    }

    destroy() {
       window.removeEventListener('resize', this.resizeHandler);
    }
}
export default About;