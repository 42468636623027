import { getCountryLngLat, getCountryISO, tansformName, setColor} from './utils/mapping'
import {statCounter} from './StatCounter'
import moment from 'moment';
import InView from 'inview';
import StatChart from './StatChart';
import barba from '@barba/core';

class Data {
    constructor() {
        this.namespaces = [document.querySelector('.page-data')].filter(Boolean);
        this.siteWidth = 1100;
        this.animating = false;
        this.mapContainer = document.getElementById('map');
        this.mapPopup = document.querySelector('.popup');
        this.mapStat = document.querySelector('.map-stat');
        this.mapStatCountry = document.querySelector('.map-stat-country');
        this.selectedMonth = window.EWM_DATA ? window.EWM_DATA.selectedDate : null;
        this.monthlyData = window.EWM_DATA ? this.htmlDecode(window.EWM_DATA.data) : [];
        this.layerIds = [];
        this.statsAnimated = false;
        this.statsAnimatedServices = false
        this.percentageStateAnimated = false;
        this.selector = document.getElementById('data-selector');
        this.zoomIn = document.querySelector('.map-zoomin');
        this.zoomOut = document.querySelector('.map-zoomout');

        window.EWM.setMapStyle = this.setMapStyle.bind(this);


        this.initPage();
    }

    initPage() {
        if (!this.namespaces.length) return;
        this.setupWindowEvents();
        this.setupData();
        this.checkInView();
        new StatChart();
    }

    checkInView() {
        this.inviewStats = InView(document.querySelector('.stats-banner'), (isInView) => {
            if (isInView && !this.statsAnimated) {
                this.animateStats();
            }
        });

        this.inviewStatsServices = InView(document.querySelector('.services-stats'), (isInView) => {
            if (isInView && !this.statsAnimatedServices) {
                this.animateStatsServices();
            }
        });

        this.inviewPercentageStat = InView(document.querySelector('.civilian-percentage-stat'), (isInView) => {
            if (isInView && !this.percentageStateAnimated) {
                this.animatePercentageStat();
            }
        });
    }

    htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return JSON.parse(doc.documentElement.textContent);
    }

    setupData() {
        const data = document.querySelector('.json-data');
        const selected = document.querySelector('.json-data-selected');

        this.selectedData = JSON.parse(data.innerHTML);
        this.selectedMonth = moment(selected.innerHTML).format("MMYYYY");

        this.countryFilter = this.selectedData.countryData.map(({country, countryCode}) => {
            const code = countryCode;
            return { country, code }
        });

        this.setupMap();
    }

    animateStats() {
        this.statsAnimated = true;
        const statAni1 = document.querySelector('.stat-animate1');
        const statAni2 = document.querySelector('.stat-animate2');
        const statAni3 = document.querySelector('.stat-animate3');
        const statAni4 = document.querySelector('.stat-animate4');
        const statAni5 = document.querySelector('.stat-animate5');
        if (!statAni1) return;
        statCounter(statAni1);
        statCounter(statAni2);
        statCounter(statAni3);
        statCounter(statAni4);
        statCounter(statAni5);
    }

    animateStatsServices() {
        this.statsAnimatedServices = true;
        const statAni6 = document.querySelector('.stat-animate6');
        const statAni7 = document.querySelector('.stat-animate7');
        const statAni8 = document.querySelector('.stat-animate8');
        if (!statAni6) return;
        statCounter(statAni6);
        statCounter(statAni7);
        statCounter(statAni8);
    }

    animatePercentageStat() {
        this.percentageStateAnimated = true;
        const statAni9 = document.querySelector('.stat-animate9');
        statCounter(statAni9);
    }

    setupMap() {
        window.mapboxgl.accessToken = 'pk.eyJ1IjoiZnJlZW1hbmRpZ2l0YWwiLCJhIjoiY2p3YzBrZGV0MDdvOTQ5bndxemZiem5jNCJ9.4IX0pKB94mSmkrwLSJOwfQ';
        this.map = new window.mapboxgl.Map({
            container: document.getElementById('map'),
            center: [0,0],
            zoom: 1,
            style: 'mapbox://styles/freemandigital/ciypqh2kn001e2rmmjp3duqt5',
            renderWorldCopies: false,
            features: {
                simplification: 20
            }
      }
        );

        this.map.scrollZoom.disable();

        this.map.on('style.load', () => {
            this.addFeatures();
        });
    }

    addFeatures() {
        this.map.addSource("country-boundaries", {
            type: "vector",
            url: "mapbox://mapbox.country-boundaries-v1",
            promoteId: "mapbox_id"
          });

        const filter = this.countryFilter.map(({code}) => code);   
        const countryList = this.countryFilter.map(({country}) => country);

        filter.forEach((item, index) => {
            
            let stat = this.selectedData.countryData.find(({country}) => tansformName(country) === tansformName(countryList[index]));
            
            

            if (stat && item) {
                this.map.addLayer({
                    id: `country-boundaries-lines-${index}`,
                    type: "line",
                    source: "country-boundaries",
                    "source-layer": "country_boundaries",
                    filter: ["==", "iso_3166_1", item],
                    paint: {
                        "line-color": 'black',
                        "line-opacity": .3,
                    }
                });

                this.map.addLayer({
                    id: `country-boundaries-${index}`,
                    type: "fill",
                    source: "country-boundaries",
                    "source-layer": "country_boundaries",
                    filter: ["==", "iso_3166_1", item],
                    paint: {
                        "fill-color": setColor(stat.numberOfIncidents_AOAV),
                        "fill-opacity": 1,
                    }
                });

                this.layerIds.push(`country-boundaries-${index}`);
            }

            


        });   

        this.hoverState();
    }

    animateMapStat() {
        if (this.animating) return;
        this.animating = true;
        statCounter(this.mapStat);
    }

    hoverState() {
        this.map.on("mousemove", (e) => {
           
            var features = this.map.queryRenderedFeatures(e.point);
            let stat; 
            let activeCountry;

            this.map.getCanvas().style.cursor = '';
            this.mapPopup.classList.remove('active');
            this.animating = false;

            if (features.length) {
    
                activeCountry = getCountryLngLat(features[0].properties.iso_3166_1);
    
                stat = this.selectedData.countryData.find(({countryCode}) => countryCode !== null && countryCode === activeCountry.alpha2);    
   
                if (!stat) {
                    return;
                };

                this.mapStatCountry.innerHTML = stat.country;
                this.mapStat.innerHTML = stat.numberOfIncidents_AOAV;
                
                if (stat.numberOfIncidents_AOAV > 0) {
                    this.map.getCanvas().style.cursor = 'pointer';
                    this.mapPopup.classList.add('active');
                }
                
            } else {
               this.map.getCanvas().style.cursor = '';
               this.mapPopup.classList.remove('active');
               this.animating = false;
            }
        });
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
        window.addEventListener('mousemove', this.mouseMoveHandler.bind(this));
        this.selector.addEventListener('change', this.selectorHandler.bind(this));
        this.zoomIn.addEventListener('click', this.zoomInHandler.bind(this));
        this.zoomOut.addEventListener('click', this.zoomOutHandler.bind(this));
    }

    zoomInHandler() {
        this.map.zoomIn();
    }

    zoomOutHandler() {
        this.map.zoomOut();
    }

    mouseMoveHandler(e) {
        this.mapPopup.style.left = e.pageX - this.mapContainer.offsetLeft + 'px';
        this.mapPopup.style.top = e.pageY - this.mapContainer.offsetTop + 'px';
    }

    selectorHandler(e) {
        if (e.target.value === 'null') return;
        barba.go(`/data/${e.target.value}`);
    }

    setMapStyle(theme) {
        switch(theme) {
            case 'light':
                if (this.map) this.map.setStyle('mapbox://styles/freemandigital/ciypqh2kn001e2rmmjp3duqt5');
                break;
            case 'dark':
                if (this.map) this.map.setStyle('mapbox://styles/freemandigital/cltvomrld019t01nr2cyo62w5');
                break;
        }
    }

    resizeHandler() {
        window.EWM.updateChart();
    }

    destroy() {
       window.removeEventListener('resize', this.resizeHandler);
       window.removeEventListener('mousemove', this.mouseMoveHandler);
       this.selector.removeEventListener('change', this.selectorHandler);

       this.inviewStats.destroy();
       this.inviewStatsServices.destroy();

       this.zoomIn.removeEventListener('click', this.zoomInHandler);
       this.zoomOut.removeEventListener('click', this.zoomOutHandler);
    }
}
export default Data;