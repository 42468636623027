import barba from '@barba/core';
import gsap from 'gsap';
import Home from './Home';
import Fragments from './Fragments';
import Data from './Data';
import About from './About';
import Search from './Search';
import Resources from './Resources';
import State from './State';
import scrollDetector from 'scroll-detector';
import * as JsSearch from 'js-search';

class Site {
    constructor() {

        const style = localStorage.getItem("style");

        window.EWM = {
          style: style ? style : 'light'
        };

        this.setupRouting.bind(this);
        this.setupRouting();
        this.navigationBar = document.querySelector('.navigation');
        this.body = document.querySelector('body');
        this.pages = [];
        this.root = document.querySelector('html');
        this.navigationElements = document.querySelectorAll('.nav-link');
        this.navigationElementsParents = document.querySelectorAll('.parent-nav');        
        this.scrollDetector = new scrollDetector();
        this.navAboutLink = document.querySelector('.nav-link-about');
        this.mobileClose = document.querySelector('.mobile-close');
        this.mobileBurger = document.querySelector('.nav-mobile-burger');
        this.mobileNavLinks = document.querySelectorAll('.mobile-nav .nav-item');
        this.searchResults = [];
        this.btnSearch = document.querySelector('.btn-search');
        this.searchHeader = document.querySelector('.searchbox-header');

        this.indexSearch();
        this.initPage();
        this.setupScrollEvents();
    }

    setupScrollEvents() {
      this.scrollDetector.on( 'scroll:up', () => {
        this.navigationBar.classList.remove('hidden');
        this.navigationBar.classList.remove('trans');
        this.searchHeader.classList.remove('open');
      } );
      
      this.scrollDetector.on( 'scroll:down', () => {
        this.navigationBar.classList.add('hidden');
        this.searchHeader.classList.remove('open');
      } );
      
      this.scrollDetector.on( 'at:top', () => {
        this.navigationBar.classList.remove('hidden');
        this.navigationBar.classList.add('trans');
      } );
    }

    setupRouting() {
      const _self = this;

      barba.init({
          schema: {
              prefix: 'data-site',
              wrapper: 'app'
          },
          transitions: [{
            name: 'slide',
            // leave(data) {
            //   return gsap.to('.preloader', {
            //     right: 0
            //   });
            // },
            // afterLeave(data) {
            //   return gsap.to('.preloader', {
            //     left: '100vw',
            //     delay: .1
            //   });
            // }
          }],
          views: [{
            namespace: 'site',
            beforeLeave() {
              _self.destroyPage();
            },
            afterEnter() {
              window.scrollTo({
                  top: 0
              });

              _self.setNav();
            }
          }]
      });

      barba.hooks.before(() => {
        document.querySelector('.site-wrapper').classList.add('is-leaving');
      });

      barba.hooks.after(() => {
        this.initPage();
        document.querySelector('.site-wrapper').classList.remove('is-leaving');
      });
    }

    setNav() {
      this.navigationElements.forEach((element) => {
        element.classList.remove('active');
        if (window.location.pathname.split('/')[1] === "") return;
        if (element.getAttribute('href').includes(window.location.pathname.split('/')[1])) {
          element.classList.add('active');
        }
      });
    }

    loadPages(){
      this.pages.push(new Home());
      this.pages.push(new Fragments());
      this.pages.push(new Data());
      this.pages.push(new About());
      this.pages.push(new Resources());
      this.pages.push(new State());
      this.pages.push(new Search());
    }

    destroyPage() {
      this.pages.forEach(page => {
        page.destroy();
      });

      this.btnDarkMode.removeEventListener('click', this.setDarkMode);
      this.btnLightMode.removeEventListener('click', this.setLightMode);
    }

    initPage() {
      this.loadPages();
      this.setupListeners();
      this.setStyleMode();
      document.querySelector('.preloader').style.left = 0;
      document.querySelector('.preloader').style.right = '100vw';     
    }

    setStyleMode() {
      if (window.EWM.style === 'dark') {
        this.setDarkMode();
        return;
      } else if (window.EWM.style === 'dark') {
        this.setLightMode();
        return;
      }
    }

    setLightMode() {
      window.EWM.style = 'light';
      window.EWM.setMapStyle('light');
      this.root.setAttribute('data-theme', 'light');
      localStorage.setItem("style", "light");
      if (window.EWM.updateChart)  window.EWM.updateChart();
    }

    setDarkMode() {
      window.EWM.style = 'dark';
      window.EWM.setMapStyle('dark');
      this.root.setAttribute('data-theme', 'dark');
      localStorage.setItem("style", "dark");
      if (window.EWM.updateChart) window.EWM.updateChart();
    }

    activateNavBackground() {
      console.log('activate');
      this.navigationBar.classList.remove('trans');
    }

    deActivateNavBackground() {
      this.navigationBar.classList.add('trans');
    }

    closeMobileMenu() {
      document.querySelector('.mobile-nav').classList.remove('open');
    }

    closeMobileMenuDelayed() {
      setTimeout(() => {
        document.querySelector('.mobile-nav').classList.remove('open');
      }, 500);
    }

    openMobileMenu() {
      document.querySelector('.mobile-nav').classList.add('open');
    }

    async indexSearch() {
      const response = await fetch('/api/search');
      const data = (await response.text()).replace(/<[^>]*>?/gm, '');
      const searchData = JSON.parse(data);

      // articles
      this.searchIndex = new JsSearch.Search('id');
      this.searchIndex.addIndex('contentA');
      this.searchIndex.addIndex('contentB');
      this.searchIndex.addIndex('contentC');
      this.searchIndex.addDocuments(searchData);
    }

    async handleMainSearch() {
      const res = this.searchIndex.search(this.mainSearchInput.value);
      const bucket = [];

      res.forEach((result) => {
        bucket.push(result.ref);
      });

      await localStorage.setItem("searchResults", JSON.stringify({ term: this.mainSearchInput.value, results: bucket }));
      barba.go('/search/results');
    }

    setupListeners() {

      document.querySelector('.searchbox-header').classList.remove('open');

      this.mainSearchForm = document.querySelector('.main-searchform');
      this.mainSearchInput = document.querySelector('.main-searchbox');
      const mainSearchButton = document.querySelector('.btn-mainsearch');

      this.btnSearch.addEventListener('click', (e) => {
        e.preventDefault();
        this.searchHeader.classList.add('open');
      });

      // if (this.mainSearchForm) {
      //   this.mainSearchForm.addEventListener('submit', (e)=>{
      //     e.preventDefault();
      //     this.handleMainSearch.bind(this);
      //   });
      // }

      mainSearchButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.handleMainSearch();
      });

      if (this.scrollDetector.isPageTop()) {
        this.navigationBar.classList.add('trans');
      }

      this.mobileClose.addEventListener('click', this.closeMobileMenu.bind(this));
      this.mobileBurger.addEventListener('click', this.openMobileMenu.bind(this));
      
      this.btnLightMode = document.querySelector('.btn-light-mode');
      this.btnDarkMode = document.querySelector('.btn-dark-mode');

      this.btnLightMode.addEventListener('click', this.setLightMode.bind(this));
      this.btnDarkMode.addEventListener('click', this.setDarkMode.bind(this));

      this.navigationElementsParents.forEach((element) => {
        element.addEventListener('mouseover', (e) => {
          element.classList.add('expanded');
          this.navigationBar.classList.remove('trans');
        });
      });

      this.mobileNavLinks.forEach((element) => {
        element.addEventListener('click', this.closeMobileMenuDelayed.bind(this));
      });

      const footerLinks = document.querySelectorAll('.footer-link');
      const footNotes = document.querySelector('.references');

      footerLinks.forEach((sup) => {
          sup.addEventListener('click', (e) => {
              footNotes.scrollIntoView({ block: 'end',  behavior: 'smooth' });
          });
      });

      this.navigationElementsParents.forEach((element) => {
        element.addEventListener('mouseout', (e) => {
          element.classList.remove('expanded');
          if (this.scrollDetector.isPageTop()) {
            this.navigationBar.classList.add('trans');
          }
        });
      });
    }
}

export default Site;