import countryISO from "./countryISO"
import countryLngLat from "./countryLngLat"

const colours = ['#ececec', '#FFE085', '#C6E1ED', '#7D9CC8', '#565C7A', '#303440'];
const coloursDarkMode = ['#333', '#FFE085', '#C6E1ED', '#7D9CC8', '#565C7A', '#303440'];

export const getCountryFromISO = (iso) => {
    if (!iso || typeof iso !== 'string') return;
    return countryISO.find((item) => item['alpha-3'].toLowerCase() === iso.toLowerCase());
}

export const getCountryISO = (country) => {
    if (!country || typeof country !== 'string') return;
    return countryISO.find((item) => item.name?.toLowerCase() === country.toLowerCase());
}

export const getCountryStat = (country) => {
    if (!country || typeof country !== 'string') return;
    return countryISO.find((item) => item.name?.toLowerCase() === country.toLowerCase() ? true : false);
}

export const getCountryLngLat = (alpha2) => {
    if (!alpha2 || typeof alpha2 !== 'string') return;
    return countryLngLat.find((item) => item.alpha2 === alpha2);
}

export const tansformName = (name) => {
    switch (name) {
        case 'Russia':
            return 'Russian Federation';
        case 'Syria':
            return 'Syrian Arab Republic';
        case 'Libyan Arab Jamahiriya':
            return 'Libya';
        case 'United States':
            return 'United States of America';
        case 'Palestine':
                return 'Palestine State of';
        default:
            return name;
    }
}

export const setColor = (stat) => {

    let style = localStorage.getItem("style");

    if (!stat) {
        return style === 'dark' ? coloursDarkMode[0] : colours[0];
    } else if (stat > 0 && stat < 6) {
        return style === 'dark' ? coloursDarkMode[1] : colours[1];
    } else if (stat > 5 && stat < 11) {
        return style === 'dark' ? coloursDarkMode[2] : colours[2];
    } else if (stat > 10 && stat < 51) {
        return style === 'dark' ? coloursDarkMode[3] : colours[3];
    } else if (stat > 50 && stat < 101) {
        return style === 'dark' ? coloursDarkMode[4] : colours[4];
    } else if (stat > 100) {
        return style === 'dark' ? coloursDarkMode[5] : colours[5];
    }

    return style === 'dark' ? coloursDarkMode[0] : colours[0];
}