import Flickity from 'flickity';
import {statCounter} from './StatCounter'
import InView from 'inview';
import barba from '@barba/core';
import moment from 'moment';

class Home {
    constructor() {
        this.namespace = document.getElementById('page-home');
        this.siteWidth = 1100;
        this.statsAnimated = false;
        
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;

        this.viewDataBtn = document.querySelectorAll('.btn-viewmore-data');
        this.dataSelector = document.querySelector('.selector-data');
        this.selectedMonth = this.dataSelector.value;

        this.checkInView();
        this.createCarousel();
        this.setupWindowEvents();
        this.setupListeners();
    }

    setupListeners(){
        this.viewDataBtn.forEach(item => item.addEventListener('click', () => this.handleViewData()));  
        this.dataSelector.addEventListener('change', () => this.handleDataChange());      
    }

    handleDataChange() {
        this.selectedMonth = this.dataSelector.value;

        fetch(`/api/stats/`)
            .then(response => response.text())
            .then(data => {
                const parsedData = data.replace(/(<([^>]+)>)/ig, '');
                const allData = this.htmlDecode(parsedData);
                const selectedData = allData.filter(d => d.month === moment(this.selectedMonth).format('MMYYYY'));
                this.updateHomeStats(selectedData);
            });
    }

    htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return JSON.parse(doc.documentElement.textContent);
    }

    handleViewData() {
        barba.go(`/data/${this.selectedMonth}`);
    }

    updateHomeStats(stats) {
        if(!stats.length) return;

        const totalCivilian = document.querySelector('.stat-total-civilian');
        const aidStat = document.querySelector('.stat-aid');
        const healthcareStat = document.querySelector('.stat-healthcare');
        const educationStat = document.querySelector('.stat-education');

        totalCivilian.innerHTML = stats[0].totalCivilianCasualties;
        aidStat.innerHTML = stats[0].aid_access;
        healthcareStat.innerHTML = stats[0].healthcare;
        educationStat.innerHTML = stats[0].education;

        this.animateState();
    }

    createCarousel() {

        this.destroySliderNavs();

        if (this.homeCarousel) {
            this.homeCarousel.destroy();
        }

        if (this.newsCarousel) {
            this.newsCarousel.destroy();
        }


        this.homeCarouselElement = document.querySelector('.carousel');
        this.newsCarouselElement = document.querySelector('.news-carousel');

        this.homeCarousel = new Flickity(this.homeCarouselElement, { pageDots: true, wrapAround: true, draggable: false,  });
        this.newsCarousel = new Flickity(this.newsCarouselElement, { cellAlign: 'left', wrapAround: false });        

        this.nextButtons = document.querySelectorAll('.btn-next');
        this.prevButtons = document.querySelectorAll('.btn-prev');
        this.nextButtonsNews = document.querySelectorAll('.btn-next-news');
        this.prevButtonsNews = document.querySelectorAll('.btn-prev-news');

        this.setSliderNavs();
        
        this.adjustSliders()
    }

    bannerNext() {
        this.homeCarousel.next();
    }

    bannerPrev() {
        this.homeCarousel.previous();
    }

    bannerNextNews() {
        this.newsCarousel.next();
    }

    bannerPrevNews() {
        this.newsCarousel.previous();
    }
  
    adjustSliders() {
        const homeCarouselDots = document.querySelector('.carousel .flickity-page-dots');
        const newsSlider = document.querySelector('.news-carousel .flickity-slider');
  
        // adjust UI positions
        newsSlider.style.paddingLeft = `${(window.innerWidth - this.siteWidth) / 2}px`;
        homeCarouselDots.style.left = `${(window.innerWidth - this.siteWidth) / 2}px`;
    }

    checkInView() {
        this.inviewStats = InView(document.querySelector('.stat-total-civilian'), (isInView) => {
            if (isInView && !this.statsAnimated) {
                this.animateState();
            }
        });
    }

    animateState() {
        this.statsAnimated = true;
        const totalCivilian = document.querySelector('.stat-total-civilian');
        const aidStat = document.querySelector('.stat-aid');
        const healthcareStat = document.querySelector('.stat-healthcare');
        const educationStat = document.querySelector('.stat-education');
  
        statCounter(totalCivilian);
        statCounter(aidStat);
        statCounter(healthcareStat);
        statCounter(educationStat);
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
       // this.createCarousel()

      this.adjustSliders();
    }

    setSliderNavs() {
        this.nextButtons.forEach((button) => {
            button.addEventListener('click', () => this.bannerNext());
        });

        this.prevButtons.forEach((button) => {
            button.addEventListener('click', () => this.bannerPrev());
        });

        this.nextButtonsNews.forEach((button) => {
            button.addEventListener('click', () => this.bannerNextNews());
        });

        this.prevButtonsNews.forEach((button) => {
            button.addEventListener('click', () => this.bannerPrevNews());
        });
    }

    destroySliderNavs() {
        if (!this.nextButtons) return;

        this.nextButtons.forEach((button) => {
            button.removeEventListener('click', () => this.bannerNext());
        });

        this.prevButtons.forEach((button) => {
            button.removeEventListener('click', () => this.bannerPrev());
        });

        this.nextButtonsNews.forEach((button) => {
            button.removeEventListener('click', () => this.bannerNextNews());
        });

        this.prevButtonsNews.forEach((button) => {
            button.removeEventListener('click', () => this.bannerPrevNews());
        });
    }

    destroy() {
        this.inviewStats.destroy();
        //this.destroySliderNavs();
    }
}
export default Home;