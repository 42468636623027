import * as JsSearch from 'js-search';
const slugify = require("slugify"); 

class State {
    constructor() {
        this.namespaces = [
            document.querySelector('.page-state-positions')
        ].filter(Boolean);

        this.siteWidth = 1100;
        this.states = [];
        this.countries = [];
    
        this.initPage();
    }

    initPage() {
        if (!this.namespaces.length) return;
        this.setupWindowEvents();
        this.setupListeners();
        this.parseJSON();
    }

    parseJSON() {
        const jsonContainer = document.querySelector('.states-json');

        if (!jsonContainer) return;
        const json = this.htmlDecode(jsonContainer.innerHTML);
        if (json) {
            json.forEach(element => {
                this.states.push({ ...element.attributes, country: element.attributes.country.data.attributes.countryName, countryCode: element.attributes.country.data.attributes.countryCode});
            });
        }

        console.log(this.states);

        this.setupSearch();
    }

    setupSearch() {
        this.search = new JsSearch.Search('country');
        this.search.addIndex(['country']);
        this.search.addDocuments(this.states);
    }

    htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return JSON.parse(doc.documentElement.textContent);
    }

    setupListeners() {
        this.searchInput = document.querySelector('.state-search');
        if (this.searchInput) this.searchInput.addEventListener('input', this.searchHandler.bind(this));
        this.resourceContainer = document.querySelector('.states-container');

        this.endorsementFilter = document.querySelector('.filter-endorsment');
        if (this.endorsementFilter) this.endorsementFilter.addEventListener('change', this.filterEndorsementHandler.bind(this));

        this.regionFilter = document.querySelector('.filter-region');
        if (this.regionFilter) this.regionFilter.addEventListener('change', this.filterRegionHandler.bind(this));

        this.selectState = document.querySelector('.select-state');
        if (this.selectState) this.selectState.addEventListener('change', this.selectStateHandler.bind(this));
    }

    selectStateHandler(e) {
        document.location.href = e.target.value;
    }

    filterRegionHandler(e) {
        const value = e.target.value;
        if (value === 'all') {
            return this.renderCards(this.states)
        }

        const results = this.states.filter(state => state.region === value.replaceAll(' ', '_'));
        if (results.length === 0) return this.renderCards([]);
        this.renderCards(results)
    }

    filterEndorsementHandler(e) {
        const value = e.target.value;
        let endorsed = true;

        if (value === 'all') {
            return this.renderCards(this.states) 
        } else if (value === 'true') { 
            endorsed = true;
        } else if (value === 'false') {
            endorsed = false;
        }

        const results = this.states.filter(state => state.endorsed === endorsed);
        if (results.length === 0) return this.renderCards([]);
        this.renderCards(results)
    }

    searchHandler(e) {
        const value = e.target.value;
        if (value === '') return this.renderCards(this.states);
        const results = this.search.search(value);
        if (results.length === 0) return this.renderCards([]);
        this.renderCards(results)
    }

    renderCards(results) {
        if (!this.resourceContainer) return;
        this.resourceContainer.innerHTML = '';

        results.forEach(result => {
            const card = this.createCard(result);
            this.resourceContainer.appendChild(card.firstChild);
        });

        if (results.length === 0) {
            const noResults = `<div class="no-results" xmlns="http://www.w3.org/1999/xhtml">Sorry no countries found</div>`;
            this.resourceContainer.appendChild(new DOMParser().parseFromString(noResults, "text/xml").firstChild);
        }
    }

    createCard(result) {
        const card = `
        <div class="country-card" xmlns="http://www.w3.org/1999/xhtml">
            <div><a href="/state-positions/${slugify(result.country, { lower: true })}"><img src="/static/icon/flags/${result.countryCode.toLowerCase()}.svg" /></a></div>
            <div><a href="/state-positions/${slugify(result.country, { lower: true })}">${this.parseTitle(result.country)}</a></div>
        </div>`;

        return new DOMParser().parseFromString(card, "text/xml");
    }

    parseTitle(title) {
        return title.replaceAll('_', ' ');
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
        
    }

    destroy() {
       window.removeEventListener('resize', this.resizeHandler);
    }
}
export default State;