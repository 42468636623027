import Chart from 'chart.js/auto';
import moment from 'moment';

class StatChart {
    constructor() {
        this.init();
    }

    init() {
        this.lineChart();
        this.barChart();
        window.EWM.updateChart = this.updateChart.bind(this);
    }

    updateChart() {

        const style = localStorage.getItem("style");
        this.lineGraph.options = {
            maintainAspectRatio: window.innerWidth > 510 ? true : false,
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        color: style === "dark" ?  "white" : "black",
                    }
                },
                x: {
                    ticks: {
                        color: style === "dark" ?  "white" : "black",
                    }
                }
            }
        }

        this.lineGraph.update();

        this.barChart.options = {
            maintainAspectRatio: window.innerWidth > 510 ? true : false,
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: true,
                    ticks: {
                        color: style === "dark" ?  "white" : "black",
                    }
                },
                x: {
                    stacked: true,
                    ticks: {
                        color: style === "dark" ?  "white" : "black",
                    }
                }
            } 
        }

        this.barChart.data.datasets = [
            {
                label: 'Aid',
                data: this.bars.map(({aid}) => aid),
                backgroundColor: '#FFD256'
            },
            {
                label: 'Education',
                data: this.bars.map(({education}) => education),
                backgroundColor: '#B8DBE6'
            },
            {
                label: 'Healthcare',
                data: this.bars.map(({healthcare}) => healthcare),
                backgroundColor: window.EWM.style === 'dark' ? '#000' : '#2C3136'
            }
        
        ];

        this.barChart.update();
    }

    barChart() {
        const data = this.htmlDecode(document.querySelector('.json-chart-services').innerHTML);
        const ctx = document.getElementById('chart-services');
        const xLabels = data.map(({month}) => moment(month).format('MMM YYYY'));
        const style = localStorage.getItem("style");

        this.bars = data.map((d) => d);

        Chart.defaults.font.family = "astoria-sans-condensed, sans-serif";
        Chart.defaults.font.size = 16;
        Chart.defaults.font.weight = 300;
        Chart.defaults.plugins.legend.position = 'bottom';
        Chart.defaults.plugins.legend.align = 'start';
        Chart.defaults.plugins.legend.labels.padding = 40;
        Chart.defaults.plugins.legend.labels.boxHeight = 2;

        this.barChart = new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: [
                    {
                        label: 'Aid',
                        data: this.bars.map(({aid}) => aid),
                        backgroundColor: '#FFD256'
                    },
                    {
                        label: 'Education',
                        data: this.bars.map(({education}) => education),
                        backgroundColor: '#B8DBE6'
                    },
                    {
                        label: 'Healthcare',
                        data: this.bars.map(({healthcare}) => healthcare),
                        backgroundColor: window.EWM.style === 'dark' ? '#000' : '#2C3136'
                    }
                
                ],
                labels: xLabels
            },
            options: {
                maintainAspectRatio: window.innerWidth > 510 ? true : false,
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: style === "dark" ?  "white" : "black",
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: style === "dark" ?  "white" : "black",
                        }
                    }
                }
            }
        });
    }

    lineChart() {
        const data = this.htmlDecode(document.querySelector('.json-chart-armedactors').innerHTML);
        const ctx = document.getElementById('armed-actor-anual');
        const xLabels = data.map(({month}) => moment(month).format('MMM YYYY'));
        const civilianData = data.map(({civilian_casualties}) => civilian_casualties);
        const actorData = data.map(({actor_casualties}) => actor_casualties);
        const style = localStorage.getItem("style");

        Chart.defaults.font.family = "astoria-sans-condensed, sans-serif";
        Chart.defaults.font.size = 16;
        Chart.defaults.font.weight = 300;
        Chart.defaults.plugins.legend.position = 'bottom';
        Chart.defaults.plugins.legend.align = 'start';
        Chart.defaults.plugins.legend.labels.padding = 40;
        Chart.defaults.plugins.legend.labels.boxHeight = 2;

        this.lineGraph = new Chart(ctx, {
            type: 'line',
            data: {
                labels: xLabels,
                datasets: [{
                    label: 'Civilian Deaths & Injuries',
                    data: civilianData,
                    borderWidth: 2,
                    borderColor: "#B7DBE5",
                    backgroundColor: "#B7DBE5",
                },
                {
                    label: 'Armed Actors Deaths & Injuries',
                    data: actorData,
                    borderWidth: 2,
                    borderColor: "#FFD256",
                    backgroundColor: "#FFD256",
                }]
            },
            options: {
                maintainAspectRatio: window.innerWidth > 510 ? true : false,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            color: style === "dark" ?  "white" : "black",
                        }
                    },
                    x: {
                        ticks: {
                            color: style === "dark" ?  "white" : "black",
                        }
                    }
                }
            }
        });
    }

    htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return JSON.parse(doc.documentElement.textContent);
    }
}

export default StatChart;