

class Search {
    constructor() {
        this.namespaces = [
            document.querySelector('.page-search')
        ].filter(Boolean);

        this.siteWidth = 1100;
     
        this.initPage();
    }

    initPage() {
        if (!this.namespaces.length) return;
        this.searchTermContainer = document.querySelector('.search-term');
        this.loadResults();
    }

    async loadResults() {
        const results = await localStorage.getItem('searchResults');
        this.parseResults(results);
    }

    parseResults(results) {
        this.searchResults = JSON.parse(results).results;
        //this.searchTermContainer.innerHTML = JSON.parse(results).term.replace(/(<([^>]+)>)/gi, "");
        this.populateResults();
    }

    populateResults() {
        const container = document.querySelector('.search-results');
        this.searchResults.forEach(result => {
            const innerContainer = document.createElement('div');
            innerContainer.classList.add('search-result');

            const h2 = document.createElement('h2');
            h2.innerHTML = result.title;
            innerContainer.appendChild(h2);

            const excerpt = document.createElement('p');
            excerpt.innerHTML = result.excerpt;
            innerContainer.appendChild(excerpt);

            const a = document.createElement('a');
            a.href = result.link;
            innerContainer.appendChild(a);

            const button = document.createElement('button');
            button.innerHTML = 'Read More';
            button.classList.add('primary');
            a.appendChild(button);

            container.appendChild(innerContainer);
        });

        if (!this.searchResults.length) {
            container.appendChild(document.createTextNode('No results found'));
        }

    }

  
    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
        
    }

    destroy() {
       window.removeEventListener('resize', this.resizeHandler);
    }
}
export default Search;