const countryLngLat = [
    {
      "Country": "Afghanistan",
      "alpha2": "AF",
      "alpha3": "AFG",
      "Numeric code": 4,
      "latitude": 33,
      "longitude": 65
    },
    {
      "Country": "Albania",
      "alpha2": "AL",
      "alpha3": "ALB",
      "Numeric code": 8,
      "latitude": 41,
      "longitude": 20
    },
    {
      "Country": "Algeria",
      "alpha2": "DZ",
      "alpha3": "DZA",
      "Numeric code": 12,
      "latitude": 28,
      "longitude": 3
    },
    {
      "Country": "American Samoa",
      "alpha2": "AS",
      "alpha3": "ASM",
      "Numeric code": 16,
      "latitude": -14.3333,
      "longitude": -170
    },
    {
      "Country": "Andorra",
      "alpha2": "AD",
      "alpha3": "AND",
      "Numeric code": 20,
      "latitude": 42.5,
      "longitude": 1.6
    },
    {
      "Country": "Angola",
      "alpha2": "AO",
      "alpha3": "AGO",
      "Numeric code": 24,
      "latitude": -12.5,
      "longitude": 18.5
    },
    {
      "Country": "Anguilla",
      "alpha2": "AI",
      "alpha3": "AIA",
      "Numeric code": 660,
      "latitude": 18.25,
      "longitude": -63.1667
    },
    {
      "Country": "Antarctica",
      "alpha2": "AQ",
      "alpha3": "ATA",
      "Numeric code": 10,
      "latitude": -90,
      "longitude": 0
    },
    {
      "Country": "Antigua and Barbuda",
      "alpha2": "AG",
      "alpha3": "ATG",
      "Numeric code": 28,
      "latitude": 17.05,
      "longitude": -61.8
    },
    {
      "Country": "Argentina",
      "alpha2": "AR",
      "alpha3": "ARG",
      "Numeric code": 32,
      "latitude": -34,
      "longitude": -64
    },
    {
      "Country": "Armenia",
      "alpha2": "AM",
      "alpha3": "ARM",
      "Numeric code": 51,
      "latitude": 40,
      "longitude": 45
    },
    {
      "Country": "Aruba",
      "alpha2": "AW",
      "alpha3": "ABW",
      "Numeric code": 533,
      "latitude": 12.5,
      "longitude": -69.9667
    },
    {
      "Country": "Australia",
      "alpha2": "AU",
      "alpha3": "AUS",
      "Numeric code": 36,
      "latitude": -27,
      "longitude": 133
    },
    {
      "Country": "Austria",
      "alpha2": "AT",
      "alpha3": "AUT",
      "Numeric code": 40,
      "latitude": 47.3333,
      "longitude": 13.3333
    },
    {
      "Country": "Azerbaijan",
      "alpha2": "AZ",
      "alpha3": "AZE",
      "Numeric code": 31,
      "latitude": 40.5,
      "longitude": 47.5
    },
    {
      "Country": "Bahamas",
      "alpha2": "BS",
      "alpha3": "BHS",
      "Numeric code": 44,
      "latitude": 24.25,
      "longitude": -76
    },
    {
      "Country": "Bahrain",
      "alpha2": "BH",
      "alpha3": "BHR",
      "Numeric code": 48,
      "latitude": 26,
      "longitude": 50.55
    },
    {
      "Country": "Bangladesh",
      "alpha2": "BD",
      "alpha3": "BGD",
      "Numeric code": 50,
      "latitude": 24,
      "longitude": 90
    },
    {
      "Country": "Barbados",
      "alpha2": "BB",
      "alpha3": "BRB",
      "Numeric code": 52,
      "latitude": 13.1667,
      "longitude": -59.5333
    },
    {
      "Country": "Belarus",
      "alpha2": "BY",
      "alpha3": "BLR",
      "Numeric code": 112,
      "latitude": 53,
      "longitude": 28
    },
    {
      "Country": "Belgium",
      "alpha2": "BE",
      "alpha3": "BEL",
      "Numeric code": 56,
      "latitude": 50.8333,
      "longitude": 4
    },
    {
      "Country": "Belize",
      "alpha2": "BZ",
      "alpha3": "BLZ",
      "Numeric code": 84,
      "latitude": 17.25,
      "longitude": -88.75
    },
    {
      "Country": "Benin",
      "alpha2": "BJ",
      "alpha3": "BEN",
      "Numeric code": 204,
      "latitude": 9.5,
      "longitude": 2.25
    },
    {
      "Country": "Bermuda",
      "alpha2": "BM",
      "alpha3": "BMU",
      "Numeric code": 60,
      "latitude": 32.3333,
      "longitude": -64.75
    },
    {
      "Country": "Bhutan",
      "alpha2": "BT",
      "alpha3": "BTN",
      "Numeric code": 64,
      "latitude": 27.5,
      "longitude": 90.5
    },
    {
      "Country": "Bolivia, Plurinational State of",
      "alpha2": "BO",
      "alpha3": "BOL",
      "Numeric code": 68,
      "latitude": -17,
      "longitude": -65
    },
    {
      "Country": "Bolivia",
      "alpha2": "BO",
      "alpha3": "BOL",
      "Numeric code": 68,
      "latitude": -17,
      "longitude": -65
    },
    {
      "Country": "Bosnia and Herzegovina",
      "alpha2": "BA",
      "alpha3": "BIH",
      "Numeric code": 70,
      "latitude": 44,
      "longitude": 18
    },
    {
      "Country": "Botswana",
      "alpha2": "BW",
      "alpha3": "BWA",
      "Numeric code": 72,
      "latitude": -22,
      "longitude": 24
    },
    {
      "Country": "Bouvet Island",
      "alpha2": "BV",
      "alpha3": "BVT",
      "Numeric code": 74,
      "latitude": -54.4333,
      "longitude": 3.4
    },
    {
      "Country": "Brazil",
      "alpha2": "BR",
      "alpha3": "BRA",
      "Numeric code": 76,
      "latitude": -10,
      "longitude": -55
    },
    {
      "Country": "British Indian Ocean Territory",
      "alpha2": "IO",
      "alpha3": "IOT",
      "Numeric code": 86,
      "latitude": -6,
      "longitude": 71.5
    },
    {
      "Country": "Brunei Darussalam",
      "alpha2": "BN",
      "alpha3": "BRN",
      "Numeric code": 96,
      "latitude": 4.5,
      "longitude": 114.6667
    },
    {
      "Country": "Brunei",
      "alpha2": "BN",
      "alpha3": "BRN",
      "Numeric code": 96,
      "latitude": 4.5,
      "longitude": 114.6667
    },
    {
      "Country": "Bulgaria",
      "alpha2": "BG",
      "alpha3": "BGR",
      "Numeric code": 100,
      "latitude": 43,
      "longitude": 25
    },
    {
      "Country": "Burkina Faso",
      "alpha2": "BF",
      "alpha3": "BFA",
      "Numeric code": 854,
      "latitude": 13,
      "longitude": -2
    },
    {
      "Country": "Burundi",
      "alpha2": "BI",
      "alpha3": "BDI",
      "Numeric code": 108,
      "latitude": -3.5,
      "longitude": 30
    },
    {
      "Country": "Cambodia",
      "alpha2": "KH",
      "alpha3": "KHM",
      "Numeric code": 116,
      "latitude": 13,
      "longitude": 105
    },
    {
      "Country": "Cameroon",
      "alpha2": "CM",
      "alpha3": "CMR",
      "Numeric code": 120,
      "latitude": 6,
      "longitude": 12
    },
    {
      "Country": "Canada",
      "alpha2": "CA",
      "alpha3": "CAN",
      "Numeric code": 124,
      "latitude": 60,
      "longitude": -95
    },
    {
      "Country": "Cape Verde",
      "alpha2": "CV",
      "alpha3": "CPV",
      "Numeric code": 132,
      "latitude": 16,
      "longitude": -24
    },
    {
      "Country": "Cayman Islands",
      "alpha2": "KY",
      "alpha3": "CYM",
      "Numeric code": 136,
      "latitude": 19.5,
      "longitude": -80.5
    },
    {
      "Country": "Central African Republic",
      "alpha2": "CF",
      "alpha3": "CAF",
      "Numeric code": 140,
      "latitude": 7,
      "longitude": 21
    },
    {
      "Country": "Chad",
      "alpha2": "TD",
      "alpha3": "TCD",
      "Numeric code": 148,
      "latitude": 15,
      "longitude": 19
    },
    {
      "Country": "Chile",
      "alpha2": "CL",
      "alpha3": "CHL",
      "Numeric code": 152,
      "latitude": -30,
      "longitude": -71
    },
    {
      "Country": "China",
      "alpha2": "CN",
      "alpha3": "CHN",
      "Numeric code": 156,
      "latitude": 35,
      "longitude": 105
    },
    {
      "Country": "Christmas Island",
      "alpha2": "CX",
      "alpha3": "CXR",
      "Numeric code": 162,
      "latitude": -10.5,
      "longitude": 105.6667
    },
    {
      "Country": "Cocos (Keeling) Islands",
      "alpha2": "CC",
      "alpha3": "CCK",
      "Numeric code": 166,
      "latitude": -12.5,
      "longitude": 96.8333
    },
    {
      "Country": "Colombia",
      "alpha2": "CO",
      "alpha3": "COL",
      "Numeric code": 170,
      "latitude": 4,
      "longitude": -72
    },
    {
      "Country": "Comoros",
      "alpha2": "KM",
      "alpha3": "COM",
      "Numeric code": 174,
      "latitude": -12.1667,
      "longitude": 44.25
    },
    {
      "Country": "Congo",
      "alpha2": "CG",
      "alpha3": "COG",
      "Numeric code": 178,
      "latitude": -1,
      "longitude": 15
    },
    {
      "Country": "Congo, the Democratic Republic of the",
      "alpha2": "CD",
      "alpha3": "COD",
      "Numeric code": 180,
      "latitude": 0,
      "longitude": 25
    },
    {
      "Country": "Cook Islands",
      "alpha2": "CK",
      "alpha3": "COK",
      "Numeric code": 184,
      "latitude": -21.2333,
      "longitude": -159.7667
    },
    {
      "Country": "Costa Rica",
      "alpha2": "CR",
      "alpha3": "CRI",
      "Numeric code": 188,
      "latitude": 10,
      "longitude": -84
    },
    {
      "Country": "Côte d'Ivoire",
      "alpha2": "CI",
      "alpha3": "CIV",
      "Numeric code": 384,
      "latitude": 8,
      "longitude": -5
    },
    {
      "Country": "Ivory Coast",
      "alpha2": "CI",
      "alpha3": "CIV",
      "Numeric code": 384,
      "latitude": 8,
      "longitude": -5
    },
    {
      "Country": "Croatia",
      "alpha2": "HR",
      "alpha3": "HRV",
      "Numeric code": 191,
      "latitude": 45.1667,
      "longitude": 15.5
    },
    {
      "Country": "Cuba",
      "alpha2": "CU",
      "alpha3": "CUB",
      "Numeric code": 192,
      "latitude": 21.5,
      "longitude": -80
    },
    {
      "Country": "Cyprus",
      "alpha2": "CY",
      "alpha3": "CYP",
      "Numeric code": 196,
      "latitude": 35,
      "longitude": 33
    },
    {
      "Country": "Czech Republic",
      "alpha2": "CZ",
      "alpha3": "CZE",
      "Numeric code": 203,
      "latitude": 49.75,
      "longitude": 15.5
    },
    {
      "Country": "Denmark",
      "alpha2": "DK",
      "alpha3": "DNK",
      "Numeric code": 208,
      "latitude": 56,
      "longitude": 10
    },
    {
      "Country": "Djibouti",
      "alpha2": "DJ",
      "alpha3": "DJI",
      "Numeric code": 262,
      "latitude": 11.5,
      "longitude": 43
    },
    {
      "Country": "Dominica",
      "alpha2": "DM",
      "alpha3": "DMA",
      "Numeric code": 212,
      "latitude": 15.4167,
      "longitude": -61.3333
    },
    {
      "Country": "Dominican Republic",
      "alpha2": "DO",
      "alpha3": "DOM",
      "Numeric code": 214,
      "latitude": 19,
      "longitude": -70.6667
    },
    {
      "Country": "Ecuador",
      "alpha2": "EC",
      "alpha3": "ECU",
      "Numeric code": 218,
      "latitude": -2,
      "longitude": -77.5
    },
    {
      "Country": "Egypt",
      "alpha2": "EG",
      "alpha3": "EGY",
      "Numeric code": 818,
      "latitude": 27,
      "longitude": 30
    },
    {
      "Country": "El Salvador",
      "alpha2": "SV",
      "alpha3": "SLV",
      "Numeric code": 222,
      "latitude": 13.8333,
      "longitude": -88.9167
    },
    {
      "Country": "Equatorial Guinea",
      "alpha2": "GQ",
      "alpha3": "GNQ",
      "Numeric code": 226,
      "latitude": 2,
      "longitude": 10
    },
    {
      "Country": "Eritrea",
      "alpha2": "ER",
      "alpha3": "ERI",
      "Numeric code": 232,
      "latitude": 15,
      "longitude": 39
    },
    {
      "Country": "Estonia",
      "alpha2": "EE",
      "alpha3": "EST",
      "Numeric code": 233,
      "latitude": 59,
      "longitude": 26
    },
    {
      "Country": "Ethiopia",
      "alpha2": "ET",
      "alpha3": "ETH",
      "Numeric code": 231,
      "latitude": 8,
      "longitude": 38
    },
    {
      "Country": "Falkland Islands (Malvinas)",
      "alpha2": "FK",
      "alpha3": "FLK",
      "Numeric code": 238,
      "latitude": -51.75,
      "longitude": -59
    },
    {
      "Country": "Faroe Islands",
      "alpha2": "FO",
      "alpha3": "FRO",
      "Numeric code": 234,
      "latitude": 62,
      "longitude": -7
    },
    {
      "Country": "Fiji",
      "alpha2": "FJ",
      "alpha3": "FJI",
      "Numeric code": 242,
      "latitude": -18,
      "longitude": 175
    },
    {
      "Country": "Finland",
      "alpha2": "FI",
      "alpha3": "FIN",
      "Numeric code": 246,
      "latitude": 64,
      "longitude": 26
    },
    {
      "Country": "France",
      "alpha2": "FR",
      "alpha3": "FRA",
      "Numeric code": 250,
      "latitude": 46,
      "longitude": 2
    },
    {
      "Country": "French Guiana",
      "alpha2": "GF",
      "alpha3": "GUF",
      "Numeric code": 254,
      "latitude": 4,
      "longitude": -53
    },
    {
      "Country": "French Polynesia",
      "alpha2": "PF",
      "alpha3": "PYF",
      "Numeric code": 258,
      "latitude": -15,
      "longitude": -140
    },
    {
      "Country": "French Southern Territories",
      "alpha2": "TF",
      "alpha3": "ATF",
      "Numeric code": 260,
      "latitude": -43,
      "longitude": 67
    },
    {
      "Country": "Gabon",
      "alpha2": "GA",
      "alpha3": "GAB",
      "Numeric code": 266,
      "latitude": -1,
      "longitude": 11.75
    },
    {
      "Country": "Gambia",
      "alpha2": "GM",
      "alpha3": "GMB",
      "Numeric code": 270,
      "latitude": 13.4667,
      "longitude": -16.5667
    },
    {
      "Country": "Georgia",
      "alpha2": "GE",
      "alpha3": "GEO",
      "Numeric code": 268,
      "latitude": 42,
      "longitude": 43.5
    },
    {
      "Country": "Germany",
      "alpha2": "DE",
      "alpha3": "DEU",
      "Numeric code": 276,
      "latitude": 51,
      "longitude": 9
    },
    {
      "Country": "Ghana",
      "alpha2": "GH",
      "alpha3": "GHA",
      "Numeric code": 288,
      "latitude": 8,
      "longitude": -2
    },
    {
      "Country": "Gibraltar",
      "alpha2": "GI",
      "alpha3": "GIB",
      "Numeric code": 292,
      "latitude": 36.1833,
      "longitude": -5.3667
    },
    {
      "Country": "Greece",
      "alpha2": "GR",
      "alpha3": "GRC",
      "Numeric code": 300,
      "latitude": 39,
      "longitude": 22
    },
    {
      "Country": "Greenland",
      "alpha2": "GL",
      "alpha3": "GRL",
      "Numeric code": 304,
      "latitude": 72,
      "longitude": -40
    },
    {
      "Country": "Grenada",
      "alpha2": "GD",
      "alpha3": "GRD",
      "Numeric code": 308,
      "latitude": 12.1167,
      "longitude": -61.6667
    },
    {
      "Country": "Guadeloupe",
      "alpha2": "GP",
      "alpha3": "GLP",
      "Numeric code": 312,
      "latitude": 16.25,
      "longitude": -61.5833
    },
    {
      "Country": "Guam",
      "alpha2": "GU",
      "alpha3": "GUM",
      "Numeric code": 316,
      "latitude": 13.4667,
      "longitude": 144.7833
    },
    {
      "Country": "Guatemala",
      "alpha2": "GT",
      "alpha3": "GTM",
      "Numeric code": 320,
      "latitude": 15.5,
      "longitude": -90.25
    },
    {
      "Country": "Guernsey",
      "alpha2": "GG",
      "alpha3": "GGY",
      "Numeric code": 831,
      "latitude": 49.5,
      "longitude": -2.56
    },
    {
      "Country": "Guinea",
      "alpha2": "GN",
      "alpha3": "GIN",
      "Numeric code": 324,
      "latitude": 11,
      "longitude": -10
    },
    {
      "Country": "Guinea-Bissau",
      "alpha2": "GW",
      "alpha3": "GNB",
      "Numeric code": 624,
      "latitude": 12,
      "longitude": -15
    },
    {
      "Country": "Guyana",
      "alpha2": "GY",
      "alpha3": "GUY",
      "Numeric code": 328,
      "latitude": 5,
      "longitude": -59
    },
    {
      "Country": "Haiti",
      "alpha2": "HT",
      "alpha3": "HTI",
      "Numeric code": 332,
      "latitude": 19,
      "longitude": -72.4167
    },
    {
      "Country": "Heard Island and McDonald Islands",
      "alpha2": "HM",
      "alpha3": "HMD",
      "Numeric code": 334,
      "latitude": -53.1,
      "longitude": 72.5167
    },
    {
      "Country": "Holy See (Vatican City State)",
      "alpha2": "VA",
      "alpha3": "VAT",
      "Numeric code": 336,
      "latitude": 41.9,
      "longitude": 12.45
    },
    {
      "Country": "Honduras",
      "alpha2": "HN",
      "alpha3": "HND",
      "Numeric code": 340,
      "latitude": 15,
      "longitude": -86.5
    },
    {
      "Country": "Hong Kong",
      "alpha2": "HK",
      "alpha3": "HKG",
      "Numeric code": 344,
      "latitude": 22.25,
      "longitude": 114.1667
    },
    {
      "Country": "Hungary",
      "alpha2": "HU",
      "alpha3": "HUN",
      "Numeric code": 348,
      "latitude": 47,
      "longitude": 20
    },
    {
      "Country": "Iceland",
      "alpha2": "IS",
      "alpha3": "ISL",
      "Numeric code": 352,
      "latitude": 65,
      "longitude": -18
    },
    {
      "Country": "India",
      "alpha2": "IN",
      "alpha3": "IND",
      "Numeric code": 356,
      "latitude": 20,
      "longitude": 77
    },
    {
      "Country": "Indonesia",
      "alpha2": "ID",
      "alpha3": "IDN",
      "Numeric code": 360,
      "latitude": -5,
      "longitude": 120
    },
    {
      "Country": "Iran, Islamic Republic of",
      "alpha2": "IR",
      "alpha3": "IRN",
      "Numeric code": 364,
      "latitude": 32,
      "longitude": 53
    },
    {
      "Country": "Iraq",
      "alpha2": "IQ",
      "alpha3": "IRQ",
      "Numeric code": 368,
      "latitude": 33,
      "longitude": 44
    },
    {
      "Country": "Ireland",
      "alpha2": "IE",
      "alpha3": "IRL",
      "Numeric code": 372,
      "latitude": 53,
      "longitude": -8
    },
    {
      "Country": "Isle of Man",
      "alpha2": "IM",
      "alpha3": "IMN",
      "Numeric code": 833,
      "latitude": 54.23,
      "longitude": -4.55
    },
    {
      "Country": "Israel",
      "alpha2": "IL",
      "alpha3": "ISR",
      "Numeric code": 376,
      "latitude": 31.5,
      "longitude": 34.75
    },
    {
      "Country": "Italy",
      "alpha2": "IT",
      "alpha3": "ITA",
      "Numeric code": 380,
      "latitude": 42.8333,
      "longitude": 12.8333
    },
    {
      "Country": "Jamaica",
      "alpha2": "JM",
      "alpha3": "JAM",
      "Numeric code": 388,
      "latitude": 18.25,
      "longitude": -77.5
    },
    {
      "Country": "Japan",
      "alpha2": "JP",
      "alpha3": "JPN",
      "Numeric code": 392,
      "latitude": 36,
      "longitude": 138
    },
    {
      "Country": "Jersey",
      "alpha2": "JE",
      "alpha3": "JEY",
      "Numeric code": 832,
      "latitude": 49.21,
      "longitude": -2.13
    },
    {
      "Country": "Jordan",
      "alpha2": "JO",
      "alpha3": "JOR",
      "Numeric code": 400,
      "latitude": 31,
      "longitude": 36
    },
    {
      "Country": "Kazakhstan",
      "alpha2": "KZ",
      "alpha3": "KAZ",
      "Numeric code": 398,
      "latitude": 48,
      "longitude": 68
    },
    {
      "Country": "Kenya",
      "alpha2": "KE",
      "alpha3": "KEN",
      "Numeric code": 404,
      "latitude": 1,
      "longitude": 38
    },
    {
      "Country": "Kiribati",
      "alpha2": "KI",
      "alpha3": "KIR",
      "Numeric code": 296,
      "latitude": 1.4167,
      "longitude": 173
    },
    {
      "Country": "Korea, Democratic People's Republic of",
      "alpha2": "KP",
      "alpha3": "PRK",
      "Numeric code": 408,
      "latitude": 40,
      "longitude": 127
    },
    {
      "Country": "Korea, Republic of",
      "alpha2": "KR",
      "alpha3": "KOR",
      "Numeric code": 410,
      "latitude": 37,
      "longitude": 127.5
    },
    {
      "Country": "South Korea",
      "alpha2": "KR",
      "alpha3": "KOR",
      "Numeric code": 410,
      "latitude": 37,
      "longitude": 127.5
    },
    {
      "Country": "Kuwait",
      "alpha2": "KW",
      "alpha3": "KWT",
      "Numeric code": 414,
      "latitude": 29.3375,
      "longitude": 47.6581
    },
    {
      "Country": "Kyrgyzstan",
      "alpha2": "KG",
      "alpha3": "KGZ",
      "Numeric code": 417,
      "latitude": 41,
      "longitude": 75
    },
    {
      "Country": "Lao People's Democratic Republic",
      "alpha2": "LA",
      "alpha3": "LAO",
      "Numeric code": 418,
      "latitude": 18,
      "longitude": 105
    },
    {
      "Country": "Latvia",
      "alpha2": "LV",
      "alpha3": "LVA",
      "Numeric code": 428,
      "latitude": 57,
      "longitude": 25
    },
    {
      "Country": "Lebanon",
      "alpha2": "LB",
      "alpha3": "LBN",
      "Numeric code": 422,
      "latitude": 33.8333,
      "longitude": 35.8333
    },
    {
      "Country": "Lesotho",
      "alpha2": "LS",
      "alpha3": "LSO",
      "Numeric code": 426,
      "latitude": -29.5,
      "longitude": 28.5
    },
    {
      "Country": "Liberia",
      "alpha2": "LR",
      "alpha3": "LBR",
      "Numeric code": 430,
      "latitude": 6.5,
      "longitude": -9.5
    },
    {
      "Country": "Libyan Arab Jamahiriya",
      "alpha2": "LY",
      "alpha3": "LBY",
      "Numeric code": 434,
      "latitude": 25,
      "longitude": 17
    },
    {
      "Country": "Libya",
      "alpha2": "LY",
      "alpha3": "LBY",
      "Numeric code": 434,
      "latitude": 25,
      "longitude": 17
    },
    {
      "Country": "Liechtenstein",
      "alpha2": "LI",
      "alpha3": "LIE",
      "Numeric code": 438,
      "latitude": 47.1667,
      "longitude": 9.5333
    },
    {
      "Country": "Lithuania",
      "alpha2": "LT",
      "alpha3": "LTU",
      "Numeric code": 440,
      "latitude": 56,
      "longitude": 24
    },
    {
      "Country": "Luxembourg",
      "alpha2": "LU",
      "alpha3": "LUX",
      "Numeric code": 442,
      "latitude": 49.75,
      "longitude": 6.1667
    },
    {
      "Country": "Macao",
      "alpha2": "MO",
      "alpha3": "MAC",
      "Numeric code": 446,
      "latitude": 22.1667,
      "longitude": 113.55
    },
    {
      "Country": "Macedonia, the former Yugoslav Republic of",
      "alpha2": "MK",
      "alpha3": "MKD",
      "Numeric code": 807,
      "latitude": 41.8333,
      "longitude": 22
    },
    {
      "Country": "Madagascar",
      "alpha2": "MG",
      "alpha3": "MDG",
      "Numeric code": 450,
      "latitude": -20,
      "longitude": 47
    },
    {
      "Country": "Malawi",
      "alpha2": "MW",
      "alpha3": "MWI",
      "Numeric code": 454,
      "latitude": -13.5,
      "longitude": 34
    },
    {
      "Country": "Malaysia",
      "alpha2": "MY",
      "alpha3": "MYS",
      "Numeric code": 458,
      "latitude": 2.5,
      "longitude": 112.5
    },
    {
      "Country": "Maldives",
      "alpha2": "MV",
      "alpha3": "MDV",
      "Numeric code": 462,
      "latitude": 3.25,
      "longitude": 73
    },
    {
      "Country": "Mali",
      "alpha2": "ML",
      "alpha3": "MLI",
      "Numeric code": 466,
      "latitude": 17,
      "longitude": -4
    },
    {
      "Country": "Malta",
      "alpha2": "MT",
      "alpha3": "MLT",
      "Numeric code": 470,
      "latitude": 35.8333,
      "longitude": 14.5833
    },
    {
      "Country": "Marshall Islands",
      "alpha2": "MH",
      "alpha3": "MHL",
      "Numeric code": 584,
      "latitude": 9,
      "longitude": 168
    },
    {
      "Country": "Martinique",
      "alpha2": "MQ",
      "alpha3": "MTQ",
      "Numeric code": 474,
      "latitude": 14.6667,
      "longitude": -61
    },
    {
      "Country": "Mauritania",
      "alpha2": "MR",
      "alpha3": "MRT",
      "Numeric code": 478,
      "latitude": 20,
      "longitude": -12
    },
    {
      "Country": "Mauritius",
      "alpha2": "MU",
      "alpha3": "MUS",
      "Numeric code": 480,
      "latitude": -20.2833,
      "longitude": 57.55
    },
    {
      "Country": "Mayotte",
      "alpha2": "YT",
      "alpha3": "MYT",
      "Numeric code": 175,
      "latitude": -12.8333,
      "longitude": 45.1667
    },
    {
      "Country": "Mexico",
      "alpha2": "MX",
      "alpha3": "MEX",
      "Numeric code": 484,
      "latitude": 23,
      "longitude": -102
    },
    {
      "Country": "Micronesia, Federated States of",
      "alpha2": "FM",
      "alpha3": "FSM",
      "Numeric code": 583,
      "latitude": 6.9167,
      "longitude": 158.25
    },
    {
      "Country": "Moldova, Republic of",
      "alpha2": "MD",
      "alpha3": "MDA",
      "Numeric code": 498,
      "latitude": 47,
      "longitude": 29
    },
    {
      "Country": "Monaco",
      "alpha2": "MC",
      "alpha3": "MCO",
      "Numeric code": 492,
      "latitude": 43.7333,
      "longitude": 7.4
    },
    {
      "Country": "Mongolia",
      "alpha2": "MN",
      "alpha3": "MNG",
      "Numeric code": 496,
      "latitude": 46,
      "longitude": 105
    },
    {
      "Country": "Montenegro",
      "alpha2": "ME",
      "alpha3": "MNE",
      "Numeric code": 499,
      "latitude": 42,
      "longitude": 19
    },
    {
      "Country": "Montserrat",
      "alpha2": "MS",
      "alpha3": "MSR",
      "Numeric code": 500,
      "latitude": 16.75,
      "longitude": -62.2
    },
    {
      "Country": "Morocco",
      "alpha2": "MA",
      "alpha3": "MAR",
      "Numeric code": 504,
      "latitude": 32,
      "longitude": -5
    },
    {
      "Country": "Mozambique",
      "alpha2": "MZ",
      "alpha3": "MOZ",
      "Numeric code": 508,
      "latitude": -18.25,
      "longitude": 35
    },
    {
      "Country": "Myanmar",
      "alpha2": "MM",
      "alpha3": "MMR",
      "Numeric code": 104,
      "latitude": 22,
      "longitude": 98
    },
    {
      "Country": "Burma",
      "alpha2": "MM",
      "alpha3": "MMR",
      "Numeric code": 104,
      "latitude": 22,
      "longitude": 98
    },
    {
      "Country": "Namibia",
      "alpha2": "NA",
      "alpha3": "NAM",
      "Numeric code": 516,
      "latitude": -22,
      "longitude": 17
    },
    {
      "Country": "Nauru",
      "alpha2": "NR",
      "alpha3": "NRU",
      "Numeric code": 520,
      "latitude": -0.5333,
      "longitude": 166.9167
    },
    {
      "Country": "Nepal",
      "alpha2": "NP",
      "alpha3": "NPL",
      "Numeric code": 524,
      "latitude": 28,
      "longitude": 84
    },
    {
      "Country": "Netherlands",
      "alpha2": "NL",
      "alpha3": "NLD",
      "Numeric code": 528,
      "latitude": 52.5,
      "longitude": 5.75
    },
    {
      "Country": "Netherlands Antilles",
      "alpha2": "AN",
      "alpha3": "ANT",
      "Numeric code": 530,
      "latitude": 12.25,
      "longitude": -68.75
    },
    {
      "Country": "New Caledonia",
      "alpha2": "NC",
      "alpha3": "NCL",
      "Numeric code": 540,
      "latitude": -21.5,
      "longitude": 165.5
    },
    {
      "Country": "New Zealand",
      "alpha2": "NZ",
      "alpha3": "NZL",
      "Numeric code": 554,
      "latitude": -41,
      "longitude": 174
    },
    {
      "Country": "Nicaragua",
      "alpha2": "NI",
      "alpha3": "NIC",
      "Numeric code": 558,
      "latitude": 13,
      "longitude": -85
    },
    {
      "Country": "Niger",
      "alpha2": "NE",
      "alpha3": "NER",
      "Numeric code": 562,
      "latitude": 16,
      "longitude": 8
    },
    {
      "Country": "Nigeria",
      "alpha2": "NG",
      "alpha3": "NGA",
      "Numeric code": 566,
      "latitude": 10,
      "longitude": 8
    },
    {
      "Country": "Niue",
      "alpha2": "NU",
      "alpha3": "NIU",
      "Numeric code": 570,
      "latitude": -19.0333,
      "longitude": -169.8667
    },
    {
      "Country": "Norfolk Island",
      "alpha2": "NF",
      "alpha3": "NFK",
      "Numeric code": 574,
      "latitude": -29.0333,
      "longitude": 167.95
    },
    {
      "Country": "Northern Mariana Islands",
      "alpha2": "MP",
      "alpha3": "MNP",
      "Numeric code": 580,
      "latitude": 15.2,
      "longitude": 145.75
    },
    {
      "Country": "Norway",
      "alpha2": "NO",
      "alpha3": "NOR",
      "Numeric code": 578,
      "latitude": 62,
      "longitude": 10
    },
    {
      "Country": "Oman",
      "alpha2": "OM",
      "alpha3": "OMN",
      "Numeric code": 512,
      "latitude": 21,
      "longitude": 57
    },
    {
      "Country": "Pakistan",
      "alpha2": "PK",
      "alpha3": "PAK",
      "Numeric code": 586,
      "latitude": 30,
      "longitude": 70
    },
    {
      "Country": "Palau",
      "alpha2": "PW",
      "alpha3": "PLW",
      "Numeric code": 585,
      "latitude": 7.5,
      "longitude": 134.5
    },
    {
      "Country": "Palestinian Territory, Occupied",
      "alpha2": "PS",
      "alpha3": "PSE",
      "Numeric code": 275,
      "latitude": 32,
      "longitude": 35.25
    },
    {
      "Country": "Panama",
      "alpha2": "PA",
      "alpha3": "PAN",
      "Numeric code": 591,
      "latitude": 9,
      "longitude": -80
    },
    {
      "Country": "Papua New Guinea",
      "alpha2": "PG",
      "alpha3": "PNG",
      "Numeric code": 598,
      "latitude": -6,
      "longitude": 147
    },
    {
      "Country": "Paraguay",
      "alpha2": "PY",
      "alpha3": "PRY",
      "Numeric code": 600,
      "latitude": -23,
      "longitude": -58
    },
    {
      "Country": "Peru",
      "alpha2": "PE",
      "alpha3": "PER",
      "Numeric code": 604,
      "latitude": -10,
      "longitude": -76
    },
    {
      "Country": "Philippines",
      "alpha2": "PH",
      "alpha3": "PHL",
      "Numeric code": 608,
      "latitude": 13,
      "longitude": 122
    },
    {
      "Country": "Pitcairn",
      "alpha2": "PN",
      "alpha3": "PCN",
      "Numeric code": 612,
      "latitude": -24.7,
      "longitude": -127.4
    },
    {
      "Country": "Poland",
      "alpha2": "PL",
      "alpha3": "POL",
      "Numeric code": 616,
      "latitude": 52,
      "longitude": 20
    },
    {
      "Country": "Portugal",
      "alpha2": "PT",
      "alpha3": "PRT",
      "Numeric code": 620,
      "latitude": 39.5,
      "longitude": -8
    },
    {
      "Country": "Puerto Rico",
      "alpha2": "PR",
      "alpha3": "PRI",
      "Numeric code": 630,
      "latitude": 18.25,
      "longitude": -66.5
    },
    {
      "Country": "Qatar",
      "alpha2": "QA",
      "alpha3": "QAT",
      "Numeric code": 634,
      "latitude": 25.5,
      "longitude": 51.25
    },
    {
      "Country": "Réunion",
      "alpha2": "RE",
      "alpha3": "REU",
      "Numeric code": 638,
      "latitude": -21.1,
      "longitude": 55.6
    },
    {
      "Country": "Romania",
      "alpha2": "RO",
      "alpha3": "ROU",
      "Numeric code": 642,
      "latitude": 46,
      "longitude": 25
    },
    {
      "Country": "Russian Federation",
      "alpha2": "RU",
      "alpha3": "RUS",
      "Numeric code": 643,
      "latitude": 60,
      "longitude": 100
    },
    {
      "Country": "Russia",
      "alpha2": "RU",
      "alpha3": "RUS",
      "Numeric code": 643,
      "latitude": 60,
      "longitude": 100
    },
    {
      "Country": "Rwanda",
      "alpha2": "RW",
      "alpha3": "RWA",
      "Numeric code": 646,
      "latitude": -2,
      "longitude": 30
    },
    {
      "Country": "Saint Helena, Ascension and Tristan da Cunha",
      "alpha2": "SH",
      "alpha3": "SHN",
      "Numeric code": 654,
      "latitude": -15.9333,
      "longitude": -5.7
    },
    {
      "Country": "Saint Kitts and Nevis",
      "alpha2": "KN",
      "alpha3": "KNA",
      "Numeric code": 659,
      "latitude": 17.3333,
      "longitude": -62.75
    },
    {
      "Country": "Saint Lucia",
      "alpha2": "LC",
      "alpha3": "LCA",
      "Numeric code": 662,
      "latitude": 13.8833,
      "longitude": -61.1333
    },
    {
      "Country": "Saint Pierre and Miquelon",
      "alpha2": "PM",
      "alpha3": "SPM",
      "Numeric code": 666,
      "latitude": 46.8333,
      "longitude": -56.3333
    },
    {
      "Country": "Saint Vincent and the Grenadines",
      "alpha2": "VC",
      "alpha3": "VCT",
      "Numeric code": 670,
      "latitude": 13.25,
      "longitude": -61.2
    },
    {
      "Country": "Saint Vincent & the Grenadines",
      "alpha2": "VC",
      "alpha3": "VCT",
      "Numeric code": 670,
      "latitude": 13.25,
      "longitude": -61.2
    },
    {
      "Country": "St. Vincent and the Grenadines",
      "alpha2": "VC",
      "alpha3": "VCT",
      "Numeric code": 670,
      "latitude": 13.25,
      "longitude": -61.2
    },
    {
      "Country": "Samoa",
      "alpha2": "WS",
      "alpha3": "WSM",
      "Numeric code": 882,
      "latitude": -13.5833,
      "longitude": -172.3333
    },
    {
      "Country": "San Marino",
      "alpha2": "SM",
      "alpha3": "SMR",
      "Numeric code": 674,
      "latitude": 43.7667,
      "longitude": 12.4167
    },
    {
      "Country": "Sao Tome and Principe",
      "alpha2": "ST",
      "alpha3": "STP",
      "Numeric code": 678,
      "latitude": 1,
      "longitude": 7
    },
    {
      "Country": "Saudi Arabia",
      "alpha2": "SA",
      "alpha3": "SAU",
      "Numeric code": 682,
      "latitude": 25,
      "longitude": 45
    },
    {
      "Country": "Senegal",
      "alpha2": "SN",
      "alpha3": "SEN",
      "Numeric code": 686,
      "latitude": 14,
      "longitude": -14
    },
    {
      "Country": "Serbia",
      "alpha2": "RS",
      "alpha3": "SRB",
      "Numeric code": 688,
      "latitude": 44,
      "longitude": 21
    },
    {
      "Country": "Seychelles",
      "alpha2": "SC",
      "alpha3": "SYC",
      "Numeric code": 690,
      "latitude": -4.5833,
      "longitude": 55.6667
    },
    {
      "Country": "Sierra Leone",
      "alpha2": "SL",
      "alpha3": "SLE",
      "Numeric code": 694,
      "latitude": 8.5,
      "longitude": -11.5
    },
    {
      "Country": "Singapore",
      "alpha2": "SG",
      "alpha3": "SGP",
      "Numeric code": 702,
      "latitude": 1.3667,
      "longitude": 103.8
    },
    {
      "Country": "Slovakia",
      "alpha2": "SK",
      "alpha3": "SVK",
      "Numeric code": 703,
      "latitude": 48.6667,
      "longitude": 19.5
    },
    {
      "Country": "Slovenia",
      "alpha2": "SI",
      "alpha3": "SVN",
      "Numeric code": 705,
      "latitude": 46,
      "longitude": 15
    },
    {
      "Country": "Solomon Islands",
      "alpha2": "SB",
      "alpha3": "SLB",
      "Numeric code": 90,
      "latitude": -8,
      "longitude": 159
    },
    {
      "Country": "Somalia",
      "alpha2": "SO",
      "alpha3": "SOM",
      "Numeric code": 706,
      "latitude": 10,
      "longitude": 49
    },
    {
      "Country": "South Africa",
      "alpha2": "ZA",
      "alpha3": "ZAF",
      "Numeric code": 710,
      "latitude": -29,
      "longitude": 24
    },
    {
      "Country": "South Georgia and the South Sandwich Islands",
      "alpha2": "GS",
      "alpha3": "SGS",
      "Numeric code": 239,
      "latitude": -54.5,
      "longitude": -37
    },
    {
      "Country": "South Sudan",
      "alpha2": "SS",
      "alpha3": "SSD",
      "Numeric code": 728,
      "latitude": 8,
      "longitude": 30
    },
    {
      "Country": "Spain",
      "alpha2": "ES",
      "alpha3": "ESP",
      "Numeric code": 724,
      "latitude": 40,
      "longitude": -4
    },
    {
      "Country": "Sri Lanka",
      "alpha2": "LK",
      "alpha3": "LKA",
      "Numeric code": 144,
      "latitude": 7,
      "longitude": 81
    },
    {
      "Country": "Sudan",
      "alpha2": "SD",
      "alpha3": "SDN",
      "Numeric code": 736,
      "latitude": 15,
      "longitude": 30
    },
    {
      "Country": "Suriname",
      "alpha2": "SR",
      "alpha3": "SUR",
      "Numeric code": 740,
      "latitude": 4,
      "longitude": -56
    },
    {
      "Country": "Svalbard and Jan Mayen",
      "alpha2": "SJ",
      "alpha3": "SJM",
      "Numeric code": 744,
      "latitude": 78,
      "longitude": 20
    },
    {
      "Country": "Swaziland",
      "alpha2": "SZ",
      "alpha3": "SWZ",
      "Numeric code": 748,
      "latitude": -26.5,
      "longitude": 31.5
    },
    {
      "Country": "Sweden",
      "alpha2": "SE",
      "alpha3": "SWE",
      "Numeric code": 752,
      "latitude": 62,
      "longitude": 15
    },
    {
      "Country": "Switzerland",
      "alpha2": "CH",
      "alpha3": "CHE",
      "Numeric code": 756,
      "latitude": 47,
      "longitude": 8
    },
    {
      "Country": "Syrian Arab Republic",
      "alpha2": "SY",
      "alpha3": "SYR",
      "Numeric code": 760,
      "latitude": 35,
      "longitude": 38
    },
    {
      "Country": "Taiwan, Province of China",
      "alpha2": "TW",
      "alpha3": "TWN",
      "Numeric code": 158,
      "latitude": 23.5,
      "longitude": 121
    },
    {
      "Country": "Taiwan",
      "alpha2": "TW",
      "alpha3": "TWN",
      "Numeric code": 158,
      "latitude": 23.5,
      "longitude": 121
    },
    {
      "Country": "Tajikistan",
      "alpha2": "TJ",
      "alpha3": "TJK",
      "Numeric code": 762,
      "latitude": 39,
      "longitude": 71
    },
    {
      "Country": "Tanzania, United Republic of",
      "alpha2": "TZ",
      "alpha3": "TZA",
      "Numeric code": 834,
      "latitude": -6,
      "longitude": 35
    },
    {
      "Country": "Thailand",
      "alpha2": "TH",
      "alpha3": "THA",
      "Numeric code": 764,
      "latitude": 15,
      "longitude": 100
    },
    {
      "Country": "Timor-Leste",
      "alpha2": "TL",
      "alpha3": "TLS",
      "Numeric code": 626,
      "latitude": -8.55,
      "longitude": 125.5167
    },
    {
      "Country": "Togo",
      "alpha2": "TG",
      "alpha3": "TGO",
      "Numeric code": 768,
      "latitude": 8,
      "longitude": 1.1667
    },
    {
      "Country": "Tokelau",
      "alpha2": "TK",
      "alpha3": "TKL",
      "Numeric code": 772,
      "latitude": -9,
      "longitude": -172
    },
    {
      "Country": "Tonga",
      "alpha2": "TO",
      "alpha3": "TON",
      "Numeric code": 776,
      "latitude": -20,
      "longitude": -175
    },
    {
      "Country": "Trinidad and Tobago",
      "alpha2": "TT",
      "alpha3": "TTO",
      "Numeric code": 780,
      "latitude": 11,
      "longitude": -61
    },
    {
      "Country": "Tunisia",
      "alpha2": "TN",
      "alpha3": "TUN",
      "Numeric code": 788,
      "latitude": 34,
      "longitude": 9
    },
    {
      "Country": "Turkey",
      "alpha2": "TR",
      "alpha3": "TUR",
      "Numeric code": 792,
      "latitude": 39,
      "longitude": 35
    },
    {
      "Country": "Turkmenistan",
      "alpha2": "TM",
      "alpha3": "TKM",
      "Numeric code": 795,
      "latitude": 40,
      "longitude": 60
    },
    {
      "Country": "Turks and Caicos Islands",
      "alpha2": "TC",
      "alpha3": "TCA",
      "Numeric code": 796,
      "latitude": 21.75,
      "longitude": -71.5833
    },
    {
      "Country": "Tuvalu",
      "alpha2": "TV",
      "alpha3": "TUV",
      "Numeric code": 798,
      "latitude": -8,
      "longitude": 178
    },
    {
      "Country": "Uganda",
      "alpha2": "UG",
      "alpha3": "UGA",
      "Numeric code": 800,
      "latitude": 1,
      "longitude": 32
    },
    {
      "Country": "Ukraine",
      "alpha2": "UA",
      "alpha3": "UKR",
      "Numeric code": 804,
      "latitude": 49,
      "longitude": 32
    },
    {
      "Country": "United Arab Emirates",
      "alpha2": "AE",
      "alpha3": "ARE",
      "Numeric code": 784,
      "latitude": 24,
      "longitude": 54
    },
    {
      "Country": "United Kingdom",
      "alpha2": "GB",
      "alpha3": "GBR",
      "Numeric code": 826,
      "latitude": 54,
      "longitude": -2
    },
    {
      "Country": "United States",
      "alpha2": "US",
      "alpha3": "USA",
      "Numeric code": 840,
      "latitude": 38,
      "longitude": -97
    },
    {
      "Country": "United States Minor Outlying Islands",
      "alpha2": "UM",
      "alpha3": "UMI",
      "Numeric code": 581,
      "latitude": 19.2833,
      "longitude": 166.6
    },
    {
      "Country": "Uruguay",
      "alpha2": "UY",
      "alpha3": "URY",
      "Numeric code": 858,
      "latitude": -33,
      "longitude": -56
    },
    {
      "Country": "Uzbekistan",
      "alpha2": "UZ",
      "alpha3": "UZB",
      "Numeric code": 860,
      "latitude": 41,
      "longitude": 64
    },
    {
      "Country": "Vanuatu",
      "alpha2": "VU",
      "alpha3": "VUT",
      "Numeric code": 548,
      "latitude": -16,
      "longitude": 167
    },
    {
      "Country": "Venezuela, Bolivarian Republic of",
      "alpha2": "VE",
      "alpha3": "VEN",
      "Numeric code": 862,
      "latitude": 8,
      "longitude": -66
    },
    {
      "Country": "Venezuela",
      "alpha2": "VE",
      "alpha3": "VEN",
      "Numeric code": 862,
      "latitude": 8,
      "longitude": -66
    },
    {
      "Country": "Viet Nam",
      "alpha2": "VN",
      "alpha3": "VNM",
      "Numeric code": 704,
      "latitude": 16,
      "longitude": 106
    },
    {
      "Country": "Vietnam",
      "alpha2": "VN",
      "alpha3": "VNM",
      "Numeric code": 704,
      "latitude": 16,
      "longitude": 106
    },
    {
      "Country": "Virgin Islands, British",
      "alpha2": "VG",
      "alpha3": "VGB",
      "Numeric code": 92,
      "latitude": 18.5,
      "longitude": -64.5
    },
    {
      "Country": "Virgin Islands, U.S.",
      "alpha2": "VI",
      "alpha3": "VIR",
      "Numeric code": 850,
      "latitude": 18.3333,
      "longitude": -64.8333
    },
    {
      "Country": "Wallis and Futuna",
      "alpha2": "WF",
      "alpha3": "WLF",
      "Numeric code": 876,
      "latitude": -13.3,
      "longitude": -176.2
    },
    {
      "Country": "Western Sahara",
      "alpha2": "EH",
      "alpha3": "ESH",
      "Numeric code": 732,
      "latitude": 24.5,
      "longitude": -13
    },
    {
      "Country": "Yemen",
      "alpha2": "YE",
      "alpha3": "YEM",
      "Numeric code": 887,
      "latitude": 15,
      "longitude": 48
    },
    {
      "Country": "Zambia",
      "alpha2": "ZM",
      "alpha3": "ZMB",
      "Numeric code": 894,
      "latitude": -15,
      "longitude": 30
    },
    {
      "Country": "Zimbabwe",
      "alpha2": "ZW",
      "alpha3": "ZWE",
      "Numeric code": 716,
      "latitude": -20,
      "longitude": 30
    }
   ]

   export default countryLngLat;